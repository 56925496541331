// Variables
// default prefix
css-prefix = 'tui-full-calendar-'
// Dayname
dayname = css-prefix + 'dayname-'
// Taskview
milestone = css-prefix + 'milestone-'
taskview = css-prefix + 'task-'
// Allday
allday = css-prefix + 'allday-'
weekday = css-prefix + 'weekday-'
// DayGrid
daygrid = css-prefix + 'daygrid-'
// Time
timegrid = css-prefix + 'timegrid-'
time = css-prefix + 'time-'
// Month
month = css-prefix + 'month-'

// Functions
wh(w, h)
    width: w
    height: h

pos(top, left)
    top: top
    left: left

mpzero()
    margin: 0
    padding: 0

vendor(prop, args)
    -webkit-{prop} args
    -moz-{prop} args
    {prop} args

clearfix()
    zoom: 1;
    &:after
        content: '';
        display: block;
        clear: both;
    
