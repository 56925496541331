.{css-prefix}popup
    position: absolute
    font-weight: 2.5
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1)
    clear: both

.{css-prefix}popup-container
    min-width: 474px
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1)
    background-color: #ffffff
    border: solid 1px #d5d5d5
    padding: 17px
    border-radius: 2px

.{css-prefix}popup-section
    font-size: 0
    min-height: 40px

.{css-prefix}section-calendar
    width: 176px

.{css-prefix}section-calendar.{css-prefix}hide
    height: 21px
    visibility: hidden

.{css-prefix}section-title
    width: calc(100% - 36px)
    padding-right: 4px

.{css-prefix}section-title input
    width: 365px

.{css-prefix}button.{css-prefix}section-private
    height: 32px
    padding: 8px
    font-size: 0
    margin-left: 4px

.{css-prefix}section-private.{css-prefix}public .{css-prefix}ic-private
    background: url('image/ic-unlock.png') no-repeat

.{css-prefix}section-start-date
.{css-prefix}section-end-date
    width: 176px

    input
        width: 139px

    &:hover
    &:focus
        .{css-prefix}popup-section-item
        .tui-datepicker
            border-color: #bbbbbb


.{css-prefix}popup-section-item:focus
    border-color: #bbbbbb

.{css-prefix}section-date-dash
    font-size: 12px
    color: #d5d5d5
    height: 32px
    padding: 0 4px
    vertical-align: middle

.{css-prefix}popup-section-item
    &.{css-prefix}section-allday
        border: none
        padding: 0 0 0 8px
        cursor: pointer
    &.{css-prefix}section-location
        display: block

        input
            width: 400px

.{css-prefix}section-allday .{css-prefix}icon.{css-prefix}ic-checkbox
    margin: 0

.{css-prefix}popup-section-item.{css-prefix}section-allday .{css-prefix}content
    padding-left: 4px

.{css-prefix}section-state
    width: 109px

.{css-prefix}section-state .{css-prefix}content
    width: 58px
    text-overflow: ellipsis
    overflow: hidden

.{css-prefix}popup-section-item
    height: 32px
    padding: 0 9px 0 12px
    border: 1px solid #d5d5d5
    display: inline-block
    font-size: 0
    border-radius: 2px

.{css-prefix}popup-section-item:hover
    border-color: #bbbbbb

.{css-prefix}popup-section-item:focus
    border-color: #bbbbbb

.{css-prefix}popup-section-item .{css-prefix}icon
    position: relative

.{css-prefix}icon.{css-prefix}ic-title
    top: 2px

.{css-prefix}popup-section-item .{css-prefix}content
    text-align: left
    display: inline-block
    font-size: 12px
    vertical-align: middle
    position: relative
    padding-left: 8px

.{css-prefix}section-calendar .{css-prefix}dropdown-button .{css-prefix}content
    width: 125px
    text-overflow: ellipsis
    overflow: hidden
    top: -1px

.{css-prefix}popup-section-item input
    border: none
    height: 30px
    outline: none
    display: inline-block

.{css-prefix}popup-section-item input::placeholder
    color: #bbbbbb
    font-weight: 300

.{css-prefix}dropdown
    position: relative

.{css-prefix}dropdown:hover .{css-prefix}dropdown-button
    border: 1px solid #bbbbbb

.{css-prefix}dropdown-button.{css-prefix}popup-section-item
    height: 32px
    font-size: 0
    top: -1px

.{css-prefix}dropdown-arrow
    background: url('image/ic-arrow-solid-bottom.png') no-repeat


.dropdown.open
.{css-prefix}open
    .{css-prefix}dropdown-arrow
        background: url('image/ic-arrow-solid-top.png') no-repeat

.{css-prefix}dropdown-menu
    position: absolute
    top: 31px
    padding: 4px 0
    background-color: #ffffff
    border: 1px solid #d5d5d5
    border-top: none
    border-radius: 0 0 2px 2px
    width: 100%

.{css-prefix}dropdown:hover .{css-prefix}dropdown-menu
    border: 1px solid #bbbbbb
    border-top: none

.{css-prefix}dropdown-menu
    display: none

.{css-prefix}open .{css-prefix}dropdown-menu
        display: block

.{css-prefix}dropdown-menu-item
    height: 30px
    border: none
    cursor: pointer

.{css-prefix}section-calendar .{css-prefix}dropdown-menu-item
    width: 100%;

.{css-prefix}section-state .{css-prefix}dropdown-menu-item
    width: 100%;

.{css-prefix}dropdown-menu-item:hover
    background-color: rgba(81, 92, 230, 0.05)

.{css-prefix}dropdown-menu-item .{css-prefix}content
    line-height: 30px

.{css-prefix}button.{css-prefix}popup-close
    position: absolute
    top: 10px
    right: 10px
    background-color: #ffffff
    padding: 2px
    border: none

.{css-prefix}section-button-save
    height: 36px

.{css-prefix}popup-save
    float: right

.{css-prefix}popup-arrow-border, .{css-prefix}popup-arrow-fill
    position: absolute

.{css-prefix}arrow-top .{css-prefix}popup-arrow-border
    border-top: none
    border-right: 8px solid transparent
    border-bottom: 8px solid #d5d5d5
    border-left: 8px solid transparent
    left: calc(50% - 8px)
    top: -7px

.{css-prefix}arrow-right .{css-prefix}popup-arrow-border
    border-top: 8px solid transparent
    border-right: none
    border-bottom: 8px solid transparent
    border-left: 8px solid #d5d5d5
    top: calc(50% - 8px)
    right: -7px

.{css-prefix}arrow-bottom .{css-prefix}popup-arrow-border
    border-top: 8px solid #d5d5d5
    border-right: 8px solid transparent
    border-bottom: none
    border-left: 8px solid transparent
    left: calc(50% - 8px)
    bottom: -7px

.{css-prefix}arrow-left .{css-prefix}popup-arrow-border
    border-top: 8px solid transparent
    border-right: 8px solid #d5d5d5
    border-bottom: 8px solid transparent
    border-left: none
    top: calc(50% - 8px)
    left: -7px

.{css-prefix}arrow-top .{css-prefix}popup-arrow-fill
    border-top: none
    border-right: 7px solid transparent
    border-bottom: 7px solid #ffffff
    border-left: 7px solid transparent
    left: -7px
    top: 1px

.{css-prefix}arrow-right .{css-prefix}popup-arrow-fill
    border-top: 7px solid transparent
    border-right: none
    border-bottom: 7px solid transparent
    border-left: 7px solid #ffffff
    top: -7px
    right: 1px

.{css-prefix}arrow-bottom .{css-prefix}popup-arrow-fill
    border-top: 7px solid #ffffff
    border-right: 7px solid transparent
    border-bottom: none
    border-left: 7px solid transparent
    left: -7px
    bottom: 1px

.{css-prefix}arrow-left .{css-prefix}popup-arrow-fill
    border-top: 7px solid transparent
    border-right: 7px solid #ffffff
    border-bottom: 7px solid transparent
    border-left: none
    top: -7px
    left: 1px

.{css-prefix}button
    background: #ffffff
    border: 1px solid #d5d5d5
    border-radius: 2px
    text-align: center
    outline: none
    font-size: 12px
    cursor: pointer
    color: #333

    &:hover
        border-color: #bbbbbb
        color: #333333


.{css-prefix}button:active
    background: #f9f9f9
    color: #333333


.{css-prefix}button .round
  border-radius: 25px

.{css-prefix}confirm
  width: 96px
  height: 36px
  border-radius: 40px
  background-color: #ff6618
  font-size: 12px
  font-weight: bold
  color: #ffffff
  border: none

.{css-prefix}confirm:hover
  background-color: #e55b15
  color: #ffffff

.{css-prefix}confirm:active
  background-color: #d95614
  color: #ffffff

/* icon styles */
.{css-prefix}icon.{css-prefix}right
  float: right
  top: 1px

.{css-prefix}icon .{css-prefix}none
  display: none

.{css-prefix}icon.{css-prefix}calendar-dot
  border-radius: 8px;
  width: 12px;
  height: 12px;
  margin: 1px;

input[type='checkbox'].{css-prefix}checkbox-square
  display: none

input[type='checkbox'].{css-prefix}checkbox-square + span
  display: inline-block
  cursor: pointer
  line-height: 14px
  margin-right: 8px
  width: 14px
  height: 14px
  background: url('image/ic-checkbox-normal.png') no-repeat
  vertical-align: middle

input[type='checkbox'].{css-prefix}checkbox-square:checked + span {
  background: url('image/ic-checkbox-checked.png') no-repeat;
}

input[type='checkbox'].{css-prefix}checkbox-round
  display: none

input[type='checkbox'].{css-prefix}checkbox-round + span
  display: inline-block
  cursor: pointer
  width: 14px
  height: 14px
  line-height: 14px
  vertical-align: middle
  margin-right: 8px
  border-radius: 8px
  border: solid 2px
  background: transparent

.{css-prefix}popup-top-line
  position: absolute
  border-radius: 2px 2px 0 0
  width: 100%
  height: 4px
  border: none
  top: 0

.{css-prefix}popup-detail .{css-prefix}popup-container
  width: 301px
  min-width: 301px
  padding-bottom: 0

.{css-prefix}popup-detail .{css-prefix}icon
  width: 12px
  height: 12px
  background-size: 12px
  position: relative

.{css-prefix}popup-detail .{css-prefix}icon
  margin-right: 8px

.{css-prefix}popup-detail .{css-prefix}icon.{css-prefix}ic-location-b
  top: -2px

.{css-prefix}popup-detail .{css-prefix}icon.{css-prefix}ic-user-b
  top: -2px

.{css-prefix}popup-detail .{css-prefix}icon.{css-prefix}ic-state-b
  top: -1px

.{css-prefix}popup-detail .{css-prefix}icon.{css-prefix}calendar-dot
  width: 10px
  height: 10px
  margin-right: 8px
  top: -1px

.{css-prefix}popup-detail .{css-prefix}content
  line-height: 24px
  height: 24px
  font-size: 12px
  line-height: 2

.{css-prefix}popup-detail .{css-prefix}section-header
  margin-bottom: 6px

.{css-prefix}popup-detail .{css-prefix}popup-detail-item-separate
  margin-top: 4px

.{css-prefix}popup-detail .{css-prefix}popup-detail-item-indent
  text-indent: -20px
  padding-left: 20px

.{css-prefix}popup-detail .{css-prefix}schedule-title
  font-size: 15px
  font-weight: bold
  line-height: 1.6
  word-break: break-all

.{css-prefix}popup-detail .{css-prefix}schedule-private
  display: none
  width: 16px
  height: 16px
  background: url('image/ic-lock.png') no-repeat 16px

.{css-prefix}popup-detail .{css-prefix}schedule-private .{css-prefix}ic-private
  display: block

.{css-prefix}popup-detail .{css-prefix}section-detail
  margin-bottom: 16px

.{css-prefix}popup-detail .{css-prefix}section-button
  border-top: 1px solid #e5e5e5
  font-size: 0

.{css-prefix}section-button .{css-prefix}icon
  margin-right: 4px
  top: -3px

.{css-prefix}section-button .{css-prefix}content
  position: relative
  top: 2px

.{css-prefix}popup-edit, .{css-prefix}popup-delete
  display: inline-block
  padding: 7px 9px 11px 9px
  width: calc(50% - 1px)
  outline: none
  background: none
  border: none
  cursor: pointer

.{css-prefix}popup-vertical-line
  background: #e5e5e5
  width: 1px
  height: 14px
  vertical-align: middle
  display: inline-block
  margin-top: -7px

/* override tui-date-picker */
.tui-datepicker
    left: -12px
    z-index: 1
    border-color: #d5d5d5
